import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
		// background: 'white',
		'& $video': { objectFit: 'cover' },
	},
}));

const Video = ({
	url,
	playing,
	loop = false,
	muted = false,
	controls = false,
	width,
	height,
	videoFallbackImage,
}) => {
	const classes = useStyles();
	return (
		<ReactPlayer
			url={url}
			playing={playing}
			loop={loop}
			muted={muted}
			width={width}
			height={height}
			controls={controls}
			className={classes.reactPlayer}
			style={{
				backgroundImage: `url(${videoFallbackImage?.asset?.gatsbyImageData.images.fallback.src})`,
				backgroundSize: 'contain',
				// background: 'white',
			}}
			// fallback={
			// 	<img
			// 		src={videoFallbackImage?.asset?.gatsbyImageData.images.fallback.src}
			// 	/>
			// }
		/>
	);
};

export default Video;
